<template>
  <div>
    <div class="files-edit__sort">
      <div class="files-edit__sort-col1">
        <base-popover placement="topLeft">
          <a-button
            data-html2canvas-ignore="true"
            @click="goBackPassengersPage()"
            class="btn-primary"
            type="primary"
            default
            size="large"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
          </a-button>
          <template #content>Volver al programa</template>
        </base-popover>
        <div class="title-module">
          <IconUsers color="#3D3D3D" width="1.2em" height="1.2em" class="icon" />
          Rooming list
        </div>
      </div>
      <div class="files-edit__sort-col2" data-html2canvas-ignore="true">
        <a-button
          danger
          class="text-600 btn-download"
          type="default"
          size="large"
          :loading="loadingDownload"
          @click="exportToPDF"
        >
          <IconDownloadCloud width="22" height="18" />
          Descargar
        </a-button>
        <base-button type="primary" size="large" :loading="loadingDownload">
          <div style="display: flex; gap: 4px">
            <span>Enviar a hoteles</span>
          </div>
        </base-button>
      </div>
    </div>
    <div class="container-pdf">
      <div class="files-edit__services mt-5">
        <!-- Tabs superiores -->
        <a-row :gutter="16" align="middle" justify="space-between" class="mb-5">
          <a-col :span="24">
            <h2>
              <IconUsers color="#3D3D3D" width="1em" height="1em" class="icon" />
              Rooming list
            </h2>
            <a-divider orientation-margin="1px" style="border-color: #e9e9e9; height: 1px">
            </a-divider>
          </a-col>
        </a-row>
        <a-tabs v-model:activeKey="activeTab" class="service-schedule-tabs" type="card">
          <a-tab-pane key="hotel-1">
            <template #tab>
              <span class="tab-title">Casa Andina</span>
            </template>
            <HotelRoomingList />
          </a-tab-pane>
          <!-- Incidencias tab -->
          <a-tab-pane key="hotel-2">
            <template #tab>
              <span class="tab-title">Estelar</span>
            </template>
            <div class="tab-content"></div>
          </a-tab-pane>
          <a-tab-pane key="hotel-3">
            <template #tab>
              <span class="tab-title">Marriott</span>
            </template>
            <div class="tab-content"></div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import BasePopover from '@/components/files/reusables/BasePopover.vue';
  import { defineEmits, ref } from 'vue';
  import IconUsers from '@/components/icons/IconUsers.vue';
  import BaseButton from '@/components/files/reusables/BaseButton.vue';
  import IconDownloadCloud from '@/components/icons/IconDownloadCloud.vue';
  import HotelRoomingList from '@/components/files/rooming/components/HotelRoomingList.vue';
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  import { notification } from 'ant-design-vue';

  const emit = defineEmits(['onBack']);

  const activeTab = ref('hotel-1');
  const loadingDownload = ref(false);

  const exportToPDF = async () => {
    try {
      loadingDownload.value = true;

      const pdf = new jsPDF('p', 'mm', 'a4');

      // Márgenes
      const marginX = 15; // Margen izquierdo y derecho
      const marginY = 5; // Margen superior e inferior
      const pageWidth = pdf.internal.pageSize.getWidth() - marginX * 2;
      const pageHeight = pdf.internal.pageSize.getHeight() - marginY * 2;

      // Capturar el contenido de la sección PDF
      const container = document.querySelector('.container-pdf');
      if (!container) {
        throw new Error('No se encontró el contenido para exportar.');
      }

      const canvas = await html2canvas(container, {
        scale: 3, // Alta resolución
        useCORS: true, // Permite cargar imágenes externas
        backgroundColor: '#ffffff', // Fondo blanco
      });

      const imageData = canvas.toDataURL('image/png'); // Obtiene la imagen del contenido capturado
      const imageWidth = pageWidth;
      const imageHeight = (canvas.height * pageWidth) / canvas.width; // Escala proporcional

      // Añadir la imagen al PDF con márgenes
      let yPosition = marginY + 10; // Espacio después del título

      if (imageHeight <= pageHeight) {
        // Si el contenido cabe en una sola página
        pdf.addImage(imageData, 'PNG', marginX, yPosition, imageWidth, imageHeight);
      } else {
        // Si no cabe, dividir en múltiples páginas
        while (yPosition < canvas.height) {
          const partCanvas = document.createElement('canvas');
          partCanvas.width = canvas.width;
          partCanvas.height = Math.min(
            canvas.height - yPosition,
            (canvas.width / pageWidth) * pageHeight
          );

          const partContext = partCanvas.getContext('2d');
          partContext.drawImage(canvas, 0, -yPosition, canvas.width, canvas.height);

          const partImageData = partCanvas.toDataURL('image/png');
          pdf.addImage(partImageData, 'PNG', marginX, marginY, imageWidth, imageHeight);

          if (yPosition + pageHeight < canvas.height) {
            pdf.addPage();
          }

          yPosition += pageHeight;
        }
      }

      // Guardar el PDF
      pdf.save('rooming_list.pdf');

      notification.success({
        message: 'Éxito',
        description: 'Archivo PDF generado exitosamente.',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `Error al generar el archivo PDF: ${error.message}`,
      });
    } finally {
      loadingDownload.value = false;
    }
  };

  const goBackPassengersPage = () => {
    emit('onBack', false);
  };
</script>
<style scoped lang="scss">
  .title-module {
    margin-left: 10px;
  }

  .btn-download {
    width: auto;
    height: 45px;
    padding: 12px 24px 12px 24px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 14px;
    font-weight: 600 !important;
    color: #eb5757 !important;
    border: 1px solid #eb5757 !important;
    margin-right: 10px;

    svg {
      color: #eb5757 !important;
      margin-right: 10px;
    }

    &:hover {
      color: #eb5757 !important;
      background-color: #fff6f6 !important;
      border: 1px solid #eb5757 !important;
    }
  }

  /* Tabs */

  .service-schedule-tabs {
    .tab-title {
      text-transform: capitalize;
    }

    :deep(.ant-tabs-nav-list) {
      background-color: #ffffff !important;
    }

    :deep(.ant-tabs-tab-btn) {
      color: #ffffff !important;
    }

    :deep(.ant-tabs-tab) {
      background-color: #e9e9e9;
      color: #979797 !important;
      font-weight: 600;
      text-transform: capitalize !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      font-size: 14px !important;
      margin-left: 10px !important;
    }

    :deep(.ant-tabs-tab):nth-child(1) {
      margin-left: 15px !important;
    }

    :deep(.ant-tabs-tab-active) {
      background-color: #737373 !important;
      text-transform: capitalize;
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
    }

    :deep(.ant-tabs-nav) {
      margin-bottom: 0 !important;

      ::before {
        bottom: 0 !important;
      }
    }
  }
</style>
