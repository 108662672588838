<script lang="ts" setup>
  import { reactive, ref, onMounted } from 'vue';
  import type { UnwrapRef } from 'vue';
  import dayjs, { Dayjs } from 'dayjs';

  import { useSiderBarStore } from '../../store/sidebar';
  import { useQuote } from '@/quotes/composables/useQuote';
  import IconClear from '@/quotes/components/icons/IconClear.vue';
  import IconSearch from '@/quotes/components/icons/IconSearch.vue';

  import { useQuoteServices } from '@/quotes/composables/useQuoteServices';
  import type { Rule } from 'ant-design-vue/es/form';
  import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';
  import { useFilesStore } from '@/stores/files';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
  const { getLang } = useQuoteTranslations();
  // store
  // TODO: refactor to use composable instead
  const storeSidebar = useSiderBarStore();

  const myButton = ref(null);

  // Composable
  const { quote, deleteServiceSelected } = useQuote();
  const { getMiselaniosAvailable } = useQuoteServices();
  const filesStore = useFilesStore();

  const props = defineProps({
    isFile: { type: Boolean, default: false },
    items: { type: Object, default: null },
  });

  // Form
  const searchFormRef = ref();

  interface SearchForm {
    date_from: Dayjs | undefined;
    service_quantity: number;
    service_name: string;
    passengers: [];
  }

  const searchFormState: UnwrapRef<SearchForm> = reactive({
    date_from: dayjs(quote.value.date_in),
    service_name: '',
    service_quantity: 1,
    passengers: [],
  });

  const searchFormRules: Record<string, Rule[]> = {
    date_from: [
      {
        required: true,
        message: t('quote.label.select_start_day'),
        trigger: 'change',
        type: 'object',
      },
    ],
  };

  const dateFormat = 'DD/MM/YYYY';

  const search = () => {
    storeSidebar.setStatus(false, '', '');
    deleteServiceSelected();
    searchFormRef.value
      .validate()
      .then(async () => {
        if (props.isFile) {
          filesStore.initedAsync();
          let adults_ = filesStore.getQuantityAdults(searchFormState.passengers);
          let children_ = filesStore.getQuantityChildren(searchFormState.passengers);
          filesStore.putSearchPassengers(searchFormState.passengers);

          await filesStore.fetchServices({
            quantity_persons: {
              adults: adults_,
              child: children_,
              age_childs: [
                {
                  age: 1,
                },
              ],
            },
            date: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            client_id: localStorage.getItem('client_id'),
            origin: '',
            filter: searchFormState.service_name,
            type: 'all',
            experience: 'all',
            classification: 'all',
            category: 'all',
            limit: 10,
            page: 1,
          });
          filesStore.finished();
          console.log('Services: ', filesStore.getServices);
        } else {
          await getMiselaniosAvailable({
            adults: quote.value.people[0].adults | 1,
            allWords: 1, // true
            children: quote.value.people[0].child,
            date_from: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            origin: '',
            service_name: searchFormState.service_name,
          });

          storeSidebar.setStatus(true, 'service', 'search');
        }
      })
      .catch((error: string) => {
        console.log('error', error);
      });
  };
  const clearForm = () => {
    searchFormRef.value.resetFields();
  };

  onMounted(() => {
    if (
      props.items &&
      props.items.service &&
      props.items.service.service_origin &&
      props.items.service.service_type
    ) {
      searchFormState.date_from = dayjs(props.items.date_in_format);
      myButton.value.click();
    }
  });
</script>

<template>
  <a-form
    id="tours"
    class="container"
    ref="searchFormRef"
    :model="searchFormState"
    :rules="searchFormRules"
  >
    <div id="meals" class="container">
      <div class="row-box">
        <div class="input-box miscellaneous-input">
          <label for="start-date">{{ t('quote.label.date') }}: *</label>
          <a-form-item name="date_from">
            <a-date-picker
              v-model:value="searchFormState.date_from"
              id="start-date"
              :format="dateFormat"
            />
          </a-form-item>
        </div>
        <!-- <div class="input-box miscellaneous-cantidad">
        <label for="country">Cantidad: *</label>
        <a-form-item name="service_quantity">
          <a-input  type="number" v-model:value="searchFormState.service_quantity"  />
        </a-form-item>
      </div> -->
        <div class="input-box search miscellaneous">
          <label for="country">{{ t('quote.label.filter_by_words') }}: </label>
          <a-form-item name="service_name" class="w-100">
            <a-input
              v-model:value="searchFormState.service_name"
              :placeholder="t('quote.label.write_here')"
            />
          </a-form-item>
        </div>
        <div class="input-box" v-if="props.isFile">
          <label for="passengers" class="d-block mb-1"
            >{{ t('global.label.passengers') }} <b class="text-danger">*</b></label
          >
          <a-form-item
            :rules="{
              required: true,
              message: t('files.message.select_passengers'),
            }"
          >
            <a-select
              mode="tags"
              id="passengers"
              v-model:value="searchFormState.passengers"
              :fieldNames="{ label: 'label', value: 'id' }"
              style="width: 180px"
              max-tag-count="responsive"
              :options="filesStore.getFilePassengers"
            >
            </a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row-box">
        <div class="input-box search"></div>
        <div class="actions_buttons">
          <div class="text" @click="clearForm">
            <icon-clear />
            <span>{{ t('quote.label.clean_filters') }}</span>
          </div>
          <div class="search_button_container" ref="myButton" @click="search">
            <div class="search-button">
              <div class="content">
                <div class="icon">
                  <icon-search />
                </div>
                <div class="text">{{ t('quote.label.search') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>

<style lang="scss" scoped>
  form#tours.container {
    .input-box.search {
      .ant-row.ant-form-item {
        width: 59% !important;
      }
    }
  }
</style>
