<template>
  <div>
    <a-steps :current="step" size="large" class="p-5 mb-5">
      <a-step :title="showMessage(0)" :description="t('files.label.choose_modify')" />
      <a-step :title="showMessage(1)" :description="t('files.label.communication_to_provider')" />
      <a-step :title="showMessage(2)" :description="t('files.label.complete_reservation')" />
    </a-steps>

    <div class="files-edit">
      <template v-if="step !== 2">
        <div v-show="step == 0">
          <div class="d-flex justify-content-between align-items-center pt-5 pb-5">
            <div class="title">
              <font-awesome-icon :icon="['fa-solid', 'fa-pen-to-square']" class="text-danger" />
              Agregar servicio
            </div>
            <div class="actions">
              <a-button
                danger
                type="default"
                v-on:click="returnToProgram()"
                :loading="filesStore.isLoadingAsync"
                size="large"
              >
                Volver al programa
              </a-button>
            </div>
          </div>
          <service-search @onReturnToProgram="returnToProgram" @onNextStep="nextStep" />
        </div>
      </template>

      <template v-if="step == 1">
        <div class="d-flex justify-content-between align-items-center mt-5">
          <div class="title">
            <font-awesome-icon icon="fa-solid fa-comment-alt" class="text-danger" />
            {{ t('files.label.communication_to_provider') }}
          </div>
          <div class="actions">
            <a-button
              v-on:click="returnToProgram()"
              class="text-600"
              danger
              :disabled="filesStore.isLoading"
              size="large"
            >
              {{ t('global.button.return_to_program') }}
            </a-button>
          </div>
        </div>

        <service-merge
          :to="filesStore.getFileItinerariesServicesReplace"
          type="new"
          @onLoadReservation="loadReservation"
          @onPrevStep="prevStep"
          @onNextStep="nextStep"
        />
      </template>

      <template v-if="step == 2">
        <div>
          <div class="text-center">
            <h2 class="text-danger text-800">Reserva exitosa</h2>
            <div class="my-5">
              <i class="bi bi-check2-circle text-success" style="font-size: 5rem"></i>
            </div>
          </div>

          <div
            class="box-completed bg-light py-3 px-5"
            v-for="(item, index) in reservation.to"
            :key="index"
          >
            <a-row type="flex" align="middle" justify="space-between" class="mx-5">
              <a-col class="me-4">
                <span class="text-danger">
                  <b>Detalle de la reserva</b>
                </span>
              </a-col>
              <a-col flex="auto" v-if="reservation?.notes_to != '' && reservation?.notes_to != ''">
                <span class="bg-white px-3 py-2 bordered w-100 ant-row-middle">
                  <span class="d-flex mx-1">
                    <i class="bi bi-pencil"></i>
                  </span>
                  <span class="text-danger text-600">Notas para el proveedor:</span>
                  <span class="mx-2">{{ reservation.notes_to }}</span>
                </span>
              </a-col>
            </a-row>
            <a-row type="flex" align="top" justify="space-between" class="my-3 mx-5">
              <a-col>
                <p class="d-flex" style="gap: 5px">
                  <b>Número de File:</b>
                  <span>{{ filesStore.getFile.fileNumber }}</span>
                </p>
                <p class="d-flex" style="gap: 5px">
                  <b>Nombre del File:</b>
                  <span>{{ filesStore.getFile.description }}</span>
                </p>
                <p class="d-flex" style="gap: 5px">
                  <b>Nacionalidad:</b> <span>{{ showLanguage() }}</span>
                </p>
                <p class="d-flex" style="gap: 5px">
                  <b>Fecha de Reserva:</b>
                  <span>{{ dayjs().format('DD/MM/YYYY') }}</span>
                </p>
                <p class="d-flex" style="gap: 5px">
                  <b>E-mail del ejecutivo:</b>
                  <span class="text-lowercase"
                    >{{ filesStore.getFile.executiveCode }}@limatours.com.pe</span
                  >
                </p>
                <p>
                  <b class="text-danger">Fechas:</b>
                </p>
                <p class="d-flex" style="gap: 5px">
                  <b>Check-in: </b> {{ formatDate(item.search_parameters_services.date) }}
                  <!-- span class="text-danger">|</span> 15:00 -->
                </p>
              </a-col>
            </a-row>
            <a-row type="flex" align="middle" class="my-3 mx-5">
              <a-col :span="12">
                <p><b>Total Tarifa:</b> USD {{ item.price }}</p>
              </a-col>
            </a-row>
          </div>

          <div class="box-buttons mt-5">
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <a-button
                  type="primary"
                  class="px-4"
                  v-on:click="returnToProgram()"
                  default
                  :disabled="filesStore.isLoading"
                  size="large"
                >
                  {{ t('global.button.close') }}
                </a-button>
              </a-col>
            </a-row>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import {
    useFilesStore,
    useStatusesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
  } from '@/stores/files';
  import { formatDate } from '@/utils/files.js';
  import { useLanguagesStore } from '@store/global';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import ServiceSearch from '@/components/files/reusables/ServiceSearch.vue';
  import ServiceMerge from '@/components/files/reusables/ServiceMerge.vue';
  import { useRouter, useRoute } from 'vue-router';

  import { useI18n } from 'vue-i18n';
  import dayjs from 'dayjs';
  const { t } = useI18n({
    useScope: 'global',
  });

  const returnToProgram = () => {
    router.push({ name: 'files-edit', params: router.params });
  };

  const showLanguage = () => {
    return languagesStore.find(filesStore.getFile.lang).label;
  };

  const showMessage = (_step) => {
    let message = t('global.label.finalized');

    if (step.value < _step) {
      message = t('global.label.on_hold');
    }

    if (step.value == _step) {
      message = t('global.label.in_progress');
    }

    return message;
  };

  const step = ref(0); //Step number
  const reservation = ref({});

  const router = useRouter();
  const route = useRoute();
  const filesStore = useFilesStore();
  const languagesStore = useLanguagesStore();

  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();

  onBeforeMount(async () => {
    const { id } = route.params;

    if (typeof filesStore.getFile.id == 'undefined') {
      await statusesStore.fetchAll();
      await haveInvoicesStore.fetchAll();
      await revisionStagesStore.fetchAll();

      await filesStore.getById({ id });
    }

    filesStore.finished();
  });

  const nextStep = () => {
    step.value++;
  };

  const prevStep = () => {
    step.value--;
  };

  const loadReservation = async (data) => {
    console.log('RESPONSE: ', data);
    reservation.value = data.reservation;
    if (filesStore.getFile.fileNumber == 0) {
      data.params.reservation_add.entity = 'File';
      data.params.reservation_add.object_id = filesStore.getFile.id;
    }
    await filesStore.add_modify(data.params);
    nextStep();
  };
</script>
