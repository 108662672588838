export const createFilesAdapter = (field) => createFileAdapter(field);

export const createFileAdapter = (field) => ({
  id: field.id,
  clientId: field.client_id,
  reservationId: field.reservation_id,
  orderNumber: field.order_number,
  fileNumber: field.file_number,
  revisionStages: field.revision_stages,
  opeAssignStages: field.ope_assign_stages,
  executiveCode: field.executive_code,
  executiveName: field.executive_name,
  clientCode: field.client_code,
  clientName: field.client_name,
  categories: field.categories,
  description: field.description,
  dateIn: field.date_in,
  dateOut: field.date_out,
  adults: field.adults,
  children: field.children,
  infants: field.infants,
  observation: field.observation,
  haveInvoice: field.have_invoice,
  status: field.status,
  lang: field.lang.toLowerCase(),
  statusReason: field.status_reason,
  statusReasonId: field.status_reason_id,
  createdAt: field.created_at,
  deadline: field.deadline,
  vips: field.vips.map(({ file_id, vip_id, vip, id }) => ({
    fileId: file_id,
    vipId: vip_id,
    vipName: vip.name,
    id,
  })),
  amountSale: field.total_amount,
  amountCost: field.total_cost_amount,
  passengerChanges: field.passenger_changes,
  profitability: field.profitability,
  markupClient: field.markup_client,
  services: field.services,
  // custom fields
  paxs: Number(field.adults) + Number(field.children) + Number(field.infants),
  isVip: field.vips.length > 0,
  generateStatement: field.generate_statement,
  suggested_accommodation_sgl: field.suggested_accommodation_sgl,
  suggested_accommodation_dbl: field.suggested_accommodation_dbl,
  suggested_accommodation_tpl: field.suggested_accommodation_tpl,
  viewRateProtected: field.view_rate_protected,
  protectedRate: field.protected_rate,
});

export const createFileServiceAdapter = (field) => field;

export const createFilePassengerAdapter = (field) => ({
  id: field.id,
  city_iso: field.city_iso,
  country_iso: field.country_iso,
  date_birth: field.date_birth != '' && field.date_birth != null ? field.date_birth : '',
  dietary_restrictions: field.dietary_restrictions,
  doctype_iso: field.doctype_iso,
  document_number: field.document_number,
  document_type_id: field.document_type_id,
  email: field.email,
  file_id: field.file_id,
  frequent: field.frequent,
  genre: field.genre,
  medical_restrictions: field.medical_restrictions,
  name: field.name,
  given_name: field.name,
  surname: field.surnames,
  notes: field.notes,
  order_number: field.order_number,
  phone: field.phone,
  phone_code: field.phone_code,
  sequence_number: field.sequence_number,
  room_type: field.room_type,
  room_type_description: field.room_type_description,
  surnames: field.surnames,
  type: field.type,
  label:
    field.surnames != null || field.name != null
      ? (field.surnames != null ? field.surnames + ', ' : '') + field.name
      : 'Pasajero #' + field.sequence_number,
  document_url: field.document_url,
});

export const createFilePassengerCommunicationAdapter = (field) => ({
  given_name: field.name,
  surname: field.surnames,
  type: field.type,
});

export const createFileItineraryReplaceAdapter = (
  field,
  quantity,
  token_search,
  search_parameters,
  passengers
) => ({
  id: field.id,
  code: field.code,
  name: field.name,
  class: field.class,
  color_class: field.color_class,
  best_option_taken: field.best_option_taken,
  token_search: token_search,
  search_parameters: search_parameters,
  quantity: quantity,
  passengers: passengers,
  rooms: [],
});

export const createFileItineraryServiceReplaceAdapter = (
  field,
  rate,
  adults,
  children,
  quantity,
  price,
  token_search,
  search_parameters_services,
  passengers
) => ({
  service_id: field.id,
  service_ident: field.ident,
  rate_plan_id: rate.id,
  reservation_time: null,
  supplements: [],
  multiservices: [],
  date_from: search_parameters_services.date,
  quantity_adults: adults,
  quantity_child: children,
  code: field.code,
  name: field.name,
  quantity: quantity,
  passengers: passengers,
  rate: rate,
  token_search: token_search,
  search_parameters_services: search_parameters_services,
  child_ages: search_parameters_services.quantity_persons.age_childs,
  price: parseFloat(price).toFixed(2),
});

export const createFileItineraryRoomReplaceAdapter = (field) => ({
  room_id: field.room_id,
  room_type: field.room_type,
  description: field.description,
  occupation: field.occupation,
  best_price: field.best_price,
  rates: [],
});

export const createBasicFileAdapter = (field) => ({
  description: field.description,
  date_in: field.dateIn,
  client_id: field.clientId,
  client_code: field.clientCode,
  client_name: field.clientName,
  adults: field.adults,
  children: field.children,
  accommodation_sgl: field.accommodationSgl,
  accommodation_dbl: field.accommodationDbl,
  accommodation_tpl: field.accommodationTpl,
  file_category_id: field.fileCategoryId,
  generate_statement: field.generateStatement,
  reason_statement_id: field.reasonStatementId,
  lang: field.lang,
});
