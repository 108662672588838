import { getAccessToken, getUserId } from '../../utils/auth';
import request from '../../utils/request';
import requestAurora from '../../utils/requestAurora';
import requestNode from '../../utils/requestNode';
import requestSQS from '../../utils/requestSQS';

const STAGE = import.meta.env.VITE_APP_ENV;

const DEFAULT_SQS_AMAZON = `sqs-files-sync-update-${STAGE}`;
const ACCOMMODATIONS_SQS_AMAZON = `sqs-files-sync-accommodations-${STAGE}`;
const PASSENGERS_SQS_AMAZON = `sqs-files-sync-passengers-${STAGE}`;
const SCHEDULE_SQS_AMAZON = `sqs-files-sync-schedule-${STAGE}`;
const AMOUNTS_SQS_AMAZON = `sqs-files-sync-amounts-${STAGE}`;
const ADD_SQS_AMAZON = `sqs-files-sync-add-${STAGE}`;
const CANCELLATION_SQS_AMAZON = `sqs-files-sync-cancellation-${STAGE}`;
const CANCEL_SQS_AMAZON = `sqs-files-sync-cancel-file-${STAGE}`;
const ACTIVATE_SQS_AMAZON = `sqs-files-sync-activate-file-${STAGE}`;
const DEFAULT_EMAIL_NOTIFY = 'lsv@limatours.com.pe';
const DEFAULT_SERVICE = 'files';
const DEFAULT_USER_ID = getUserId();

export function fetchFiles({
  currentPage,
  perPage,
  filter = '',
  filterBy = '',
  filterByType = '',
  executiveCode = '',
  clientId = '',
  dateRange = '',
  filterNextDays = '',
  revisionStages = '',
  opeAssignStages = false,
  complete = 0,
}) {
  return request({
    method: 'GET',
    url: 'files',
    params: {
      page: currentPage,
      per_page: perPage,
      filter,
      filter_by: filterBy,
      filter_by_type: filterByType,
      executive_code: executiveCode,
      client_id: clientId,
      date_range: dateRange,
      filter_next_days: filterNextDays,
      revision_stages: revisionStages,
      ope_assign_stages: opeAssignStages,
      ...(complete !== 0 && { complete }),
    },
  });
}

export function getFile({ id, nrofile }) {
  let url = '';

  if (id != undefined) {
    url = `files/${id}`;
  }

  if (nrofile != undefined) {
    url = `files/number/${nrofile}`;
  }

  return request({
    method: 'GET',
    url: url,
    params: {},
  });
}

export function getItinerary({ itinerary_id }) {
  return request({
    method: 'GET',
    url: `files/file_itinerary/${itinerary_id}`,
    params: {},
  });
}

export function getFileReasons(params) {
  return request({
    method: 'GET',
    url: `reasons-rate`,
    params: params,
  });
}

export function getCategoriesHotel({ lang, client_id }) {
  return requestAurora({
    method: 'GET',
    url: `api/client_hotels/classes`,
    params: {
      lang: lang,
      client_id: client_id,
    },
  });
}

export function getDestiniesByClient({ client_id }) {
  return requestAurora({
    method: 'GET',
    url: `services/hotels/destinations`,
    params: {
      client_id: client_id,
    },
  });
}

export function getFilterSearchHotels(params) {
  const url = params.simulation ? `services/hotels/available` : `services/hotels/available/quote`;

  return requestAurora({
    method: 'POST',
    url: url,
    data: params,
  });
}

export function getFilterSearchServices(params) {
  const url = params.simulation ? `services/available` : `services/available`;

  return requestAurora({
    method: 'POST',
    url: url,
    data: params,
  });
}

export function updateSchedule(data) {
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: SCHEDULE_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [
        {
          access_token: getAccessToken(),
          type: data.type,
          object_id: data.id,
          file_number: data.file_number,
          new_start_time: data.new_start_time,
          new_departure_time: data.new_departure_time,
        },
      ],
    },
  });
}

export function saveFilePassengers(data) {
  return request({
    method: 'PUT',
    url: `files/${data.file_id}/passengers`,
    data: {
      passengers: data.passengers,
    },
  });
}

export function saveFileAccommodations(data) {
  return request({
    method: 'PUT',
    url: `files/${data.file_id}/accommodations`,
    data: {
      type: data.type,
      type_id: data.type_id,
      passengers: data.passengers,
    },
  });
}

export function updateFile(data) {
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: DEFAULT_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [
        {
          access_token: getAccessToken(),
          file_id: data.id,
          file_number: data.file_number,
          description: data.description,
          lang: data.lang,
        },
      ],
    },
  });
}

export function updateAccommodations(data) {
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: ACCOMMODATIONS_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [
        {
          access_token: getAccessToken(),
          file_number: data.file_number,
          type: data.type,
          object_id: data.object_id,
          passengers: data.passengers,
        },
      ],
    },
  });
}

export function updateAmounts(data, file_number) {
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: AMOUNTS_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [
        {
          access_token: getAccessToken(),
          file_number: file_number,
          type: data.type,
          object_id: data.object_id,
          file_amount_reason_id: data.reason_id,
          new_amount: data.new_amount,
        },
      ],
    },
  });
}

export function addItinerary(data) {
  data.access_token = getAccessToken();
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: ADD_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: 'files', // De qué servicio / sistema es enviado el mensaje
        notify: ['lsv@limatours.com.pe'],
      },
      payload: [data],
    },
  });
}

export function deleteItem(data) {
  data.access_token = getAccessToken();
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: CANCELLATION_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [data],
    },
  });
}

export function getFileStatusReasons() {
  return request({
    method: 'GET',
    url: `status-reasons`,
  });
}

export function getFileStatusReasonsCancellation() {
  return request({
    method: 'GET',
    url: `reasons-for-cancellation`,
  });
}

export function cancelFile(data) {
  data.access_token = getAccessToken();
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: CANCEL_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [data],
    },
  });
}

export function activateFile(fileId, params, status_reason_id = 4) {
  params.status_reason_id = status_reason_id;
  params.file_id = fileId;
  params.access_token = getAccessToken();

  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: ACTIVATE_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [params],
    },
  });
}

export function fetchAllProviders(object_code) {
  return requestNode({
    method: 'GET',
    url: `providers/${object_code}`,
  });
}

export function verifyQuoteA2() {
  return request({
    method: 'POST',
    url: `files/quote/board`,
  });
}

export function sendQuoteA2(fileId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/quote/board`,
    data: params,
  });
}

export function passengerDownload({ fileId }) {
  return request({
    method: 'GET',
    url: `files/${fileId}/passenger-download`,
    responseType: 'blob',
  });
}

export function passengerDownloadAmadeus({ fileId }) {
  return request({
    method: 'GET',
    url: `files/${fileId}/passenger-download-amadeus`,
    responseType: 'blob',
  });
}

export function passengerDownloadPerurail({ fileId }) {
  return request({
    method: 'GET',
    url: `files/${fileId}/passenger-download-perurail`,
    responseType: 'blob',
  });
}

export function updateAllPassengers({ fileId, data }) {
  let params = {
    file_id: fileId,
    passengers: data,
    access_token: getAccessToken(),
  };

  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: PASSENGERS_SQS_AMAZON, // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3 && Informix', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: [DEFAULT_EMAIL_NOTIFY],
      },
      payload: [params],
    },
  });
}

export function storeRepository({ userId, fileNumber, data }) {
  for (const pax of data) {
    if (pax.document_url != null && pax.document_url != '') {
      let new_files = {
        name: pax.document_url,
        file_url: pax.document_url,
      };

      let params = {
        user: userId,
        files: [new_files],
        nroref: fileNumber,
        type_id: 7,
        information_aditional: '',
        flag_hide: 0,
        passengers: [pax.id],
      };

      return requestAurora({
        method: 'POST',
        url: 'passenger_files',
        data: params,
      });
    }
  }
}

export function createBasicFile(params) {
  return request({
    method: 'POST',
    url: `files/create-basic-file`,
    data: params,
  });
}

export function cloneBasicFile(fileId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/clone-file`,
    data: params,
  });
}

export function getFileCategories() {
  return request({
    method: 'GET',
    url: `files/categories`,
  });
}

export function getReasonStatement() {
  return request({
    method: 'GET',
    url: `files/reason-statement`,
  });
}

export function getMasterServices({ currentPage, perPage, type_service, filter }) {
  // Construir los parámetros de consulta
  const queryParams = new URLSearchParams();

  if (currentPage) queryParams.append('page', currentPage.toString());
  if (perPage) queryParams.append('per_page', perPage.toString());
  if (type_service) queryParams.append('type_service', type_service);
  if (filter) queryParams.append('filter', filter);

  return request({
    method: 'GET',
    url: `master-services?${queryParams.toString()}`, // Incluir los parámetros de consulta en la URL
  });
}

export function updateQuantityPassengers(object_id, params) {
  return request({
    method: 'PUT',
    url: `files/itineraries/${object_id}/number-of-passengers`,
    data: params,
  });
}

export function getAllModifyPaxs(file_id) {
  return request({
    method: 'GET',
    url: `files/${file_id}/passenger-modify-paxs`,
  });
}

export function saveServiceTemporary(params) {
  return request({
    method: 'POST',
    url: `temporary-service`,
    data: params,
  });
}

export function getCommunicationsTemporary(fileId, serviceId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/itineraries/${serviceId}/communication-temporary-service`,
    data: params,
  });
}

export function getSuppliersByCodes(params) {
  return requestNode({
    method: 'POST',
    url: `providers/codes`,
    data: params,
  });
}

export function updateCommunicationsTemporary(fileId, serviceId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/itineraries/${serviceId}/communication-temporary-service-type`,
    data: params,
  });
}

export function addServiceMaster(serviceId, params) {
  return request({
    method: 'POST',
    url: `files/itineraries/${serviceId}/services`,
    data: params,
  });
}

export function deleteServiceMaster(serviceId, serviceMasterId) {
  return request({
    method: 'DELETE',
    url: `files/itineraries/${serviceId}/services/${serviceMasterId}`,
  });
}

export function sendCommunicationMasterService(supplier_emails, type, attachments, html) {
  console.log(supplier_emails);
  let subject = '';
  if (type === 'cancellation') {
    subject = 'Cancelación de reserva';
  } else if (type === 'reservations') {
    subject = 'Solicitud de reserva';
  } else if (type === 'modification') {
    subject = 'Reserva modificada';
  }
  return requestSQS({
    method: 'POST',
    url: 'sqs/publish',
    data: {
      queueName: 'sqs-notifications-dev', // Cola dónde será dirigido el mensaje
      metadata: {
        origin: 'A3-Front', // De qué servicio / sistema es enviado el mensaje
        destination: 'A3', // A qué servicio se dirige el mensaje
        user: DEFAULT_USER_ID, // Usuario que envía el mensaje
        service: DEFAULT_SERVICE, // De qué servicio / sistema es enviado el mensaje
        notify: ['jgq@limatours.com.pe'],
      },
      payload: [
        {
          access_token: getAccessToken(),
          module: 'files',
          subject: subject,
          to: ['jgq@limatours.com.pe'],
          body: html,
          attachments: attachments,
        },
      ],
    },
  });
}

export function fetchServiceInformation(object_id, lang, date_out, paxs) {
  return requestAurora({
    method: 'GET',
    url: `api/service/${object_id}/moreDetails?lang=${lang}&date_out=${date_out}&total_pax=${paxs}`,
  });
}

export function saveServiceTemporaryToFile(fileServiceId, params) {
  return request({
    method: 'POST',
    url: `files/${fileServiceId}/itineraries/${fileServiceId}/associate-temporary-service`,
    data: params,
  });
}

export function updateCategoriesFile(fileId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/categories`,
    data: params,
  });
}

export function searchCommunicationsCancellation(fileId, serviceId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/itineraries/${serviceId}/communication-service-cancellation`,
    data: params,
  });
}

export function searchCommunicationsNew(fileId, params) {
  return request({
    method: 'POST',
    url: `files/${fileId}/itineraries/communication-new-service`,
    data: params,
  });
}

export function findMasterServices(codes) {
  return requestNode({
    method: 'POST',
    url: `services/codes`,
    data: {
      equivalences: codes,
    },
  });
}

export function putFlagRateProtected(fileId, itineraryId) {
  return request({
    method: 'PUT',
    url: `files/${fileId}/itineraries/${itineraryId}/view-protected-rate`,
    data: [],
  });
}

export function putFlagFileRateProtected(fileId) {
  return request({
    method: 'PUT',
    url: `files/${fileId}/view-protected-rate`,
    data: [],
  });
}

export function searchFileReports(fileId) {
  return request({
    method: 'GET',
    url: `files/${fileId}/reports`,
    params: [],
  });
}
