<template>
  <template v-if="filesStore.isLoading">
    <div class="files-edit">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
  </template>
  <div class="files-edit" v-else>
    <a-steps :current="step">
      <a-step :title="showMessage(0)" description="Servicio y Hoteles con penalidad" />
      <a-step :title="showMessage(1)" description="Comunicaciones" />
      <a-step :title="showMessage(2)" description="Anulación completa" />
    </a-steps>

    <div v-if="step == 0">
      <div class="d-flex justify-content-between align-items-center mt-5">
        <h4 class="title"><i class="bi bi-x-circle text-danger"></i> Anular File</h4>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram()"
            class="text-600"
            danger
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.return_to_program') }}
          </a-button>
          <a-button
            type="primary"
            class="btn-danger ms-2 px-4text-600"
            v-on:click="nextStep()"
            default
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            v-if="status_reason_id != ''"
            size="large"
          >
            {{ t('global.button.continue') }}
          </a-button>
        </div>
      </div>

      <div class="bg-light p-4 mt-3">
        <a-alert type="warning" showIcon class="mb-4">
          <template #icon><smile-outlined /></template>
          <template #message>
            <div class="text-warning">Debes seleccionar un motivo para la anulación del file.</div>
          </template>
        </a-alert>
        <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-item>
            <template #label>
              <span class="text-600">Motivo para la anulación del file</span>
              <b class="text-danger px-2">*</b>
            </template>
            <a-select
              :allowClear="false"
              class="w-100"
              v-model:value="status_reason_id"
              :showSearch="true"
              :fieldNames="{ label: 'name', value: 'id' }"
              :options="status_reasons"
            >
            </a-select>
          </a-form-item>
        </a-form>

        <div class="bg-light" v-if="filesStore.getAllPenality > 0">
          <a-row type="flex" justify="space-between" style="gap: 20px">
            <a-col>
              <a-row type="flex" justify="space-between" align="middle" style="gap: 4px">
                <a-col flex="auto" class="text-info">
                  <span class="cursor-pointer" v-on:click="showPoliciesCancellation()">{{
                    t('global.label.cancellation_policies')
                  }}</span>
                </a-col>
                <a-col>
                  <span>Penalidad total por anular el FILE:</span>
                </a-col>
              </a-row>

              <div class="p-4 border bg-white mt-4">
                <a-form layout="vertical">
                  <a-form-item label="¿Quién asume la penalidad?">
                    <a-select
                      size="large"
                      :options="filesStore.getAsumedBy"
                      v-model:value="asumed_by"
                      showSearch
                      :filter-option="false"
                      :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
                    >
                    </a-select>
                  </a-form-item>

                  <a-form-item
                    label="Seleccione la especialista que asume la penalidad"
                    v-if="asumed_by == 13"
                  >
                    <a-select
                      size="large"
                      placeholder="Selecciona"
                      :options="executivesStore.getExecutives"
                      v-model:value="executive_id"
                      :field-names="{ label: 'name', value: 'id' }"
                      showSearch
                      :filter-option="false"
                      @search="searchExecutives"
                    >
                    </a-select>
                  </a-form-item>

                  <a-form-item
                    label="Seleccione el file que asume la penalidad"
                    v-if="asumed_by == 12"
                  >
                    <a-select
                      size="large"
                      placeholder="Selecciona"
                      :options="filesStore.getFiles"
                      v-model:value="file_id"
                      showSearch
                      :field-names="{ label: 'description', value: 'id' }"
                      :filter-option="false"
                      @search="searchFiles"
                    >
                    </a-select>
                  </a-form-item>

                  <a-form-item label="Motivo">
                    <a-textarea :rows="4" v-model:value="motive" />
                  </a-form-item>
                </a-form>
              </div>
            </a-col>
            <a-col flex="auto">
              <a-row type="flex" justify="start" align="middle">
                <a-col class="text-warning">
                  <svg
                    class="feather feather-check-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                    <path d="M22 4 12 14.01l-3-3" />
                  </svg>
                </a-col>
                <a-col class="text-warning ms-2">
                  <h5 class="m-0">
                    $
                    {{
                      formatNumber({
                        number: filesStore.getAllPenality,
                        digits: 2,
                      })
                    }}
                  </h5>
                </a-col>
                <a-col class="text-dark-warning ms-2">
                  <small class="text-500">Debe pagar la penalidad si anula el FILE</small>
                </a-col>
              </a-row>
              <div class="p-4 border bg-white mt-4">
                <p>Precio neto por tipo de pasajero</p>
                <a-row type="flex" justify="space-between">
                  <a-col class="text-left">
                    <small class="text-gray my-1"> Costo neto </small>
                    <p class="mb-1 text-warning">
                      <span class="text-800">
                        $
                        {{
                          formatNumber({
                            number: filesStore.getAllPenality / filesStore.getFile.adults,
                            digits: 2,
                          })
                        }}
                      </span>
                    </p>
                    <p class="mb-1 text-warning" v-if="filesStore.getFile.children > 0">
                      <span class="text-800">
                        $
                        {{
                          formatNumber({
                            number: filesStore.getAllPenality / filesStore.getFile.children,
                            digits: 2,
                          })
                        }}
                      </span>
                    </p>
                  </a-col>
                  <a-col class="text-center">
                    <small class="text-gray my-1"> {{ t('global.label.pax_type') }} </small>
                    <p class="mb-1">
                      <span class="text-700 me-1">ADL</span>
                      <i class="bi bi-person-fill"></i>
                    </p>
                    <p class="mb-1" v-if="filesStore.getFile.children > 0">
                      <span class="text-700 me-1">CHD</span>
                      <i class="bi bi-person-arms-up"></i>
                    </p>
                  </a-col>
                  <a-col class="text-center">
                    <small class="text-gray my-1"> Cantidad </small>
                    <p class="mb-1 text-warning">
                      <span class="text-800">{{
                        textPad({ text: filesStore.getFile.adults, start: '0', length: 2 })
                      }}</span>
                    </p>
                    <p class="mb-1 text-warning" v-if="filesStore.getFile.children > 0">
                      <span class="text-800">{{
                        textPad({
                          text: filesStore.getFile.children,
                          start: '0',
                          length: 2,
                        })
                      }}</span>
                    </p>
                  </a-col>
                  <a-col class="text-left">
                    <small class="text-gray my-1"> Total </small>
                    <p class="mb-1 text-warning">
                      <span class="text-800"
                        >$
                        {{
                          formatNumber({
                            number: filesStore.getAllPenality,
                            digits: 2,
                          })
                        }}</span
                      >
                    </p>
                    <p class="mb-1 text-warning" v-if="filesStore.getFile.children > 0">
                      <span class="text-800"
                        >$
                        {{
                          formatNumber({
                            number: filesStore.getAllPenality,
                            digits: 2,
                          })
                        }}</span
                      >
                    </p>
                  </a-col>
                </a-row>
                <!-- a-row
                  type="flex"
                  justify="space-between"
                >
                  <a-col flex="auto" class="text-left">
                    <p class="mb-1 text-warning">
                      <span class="text-800">$ {{ filesStore.getFileItinerary.total_amount }}</span>
                    </p>
                  </a-col>
                </a-row -->
              </div>
            </a-col>
          </a-row>
          <!-- a-row type="flex" justify="center" align="middle" class="text-danger">
            <a-col>
              <span class="mx-3 h1">
                <font-awesome-icon :icon="['fa-solid', 'fa-circle-exclamation']" />
              </span>
            </a-col>
            <a-col>
              <span class="mx-3 text-700">Penalidad total por anular el File</span>
            </a-col>
            <a-col>
              <b class="mx-3 h1 text-800"
                >$ {{ formatNumber({ number: filesStore.getAllPenality }) }}</b
              >
            </a-col>
            <a-col>
              <div class="mx-3 text-500">
                <p class="pb-0 mb-0">Penalidad total por pasajero adulto</p>
                <p class="pb-0 mb-0" v-if="filesStore.getFile.children > 0">
                  Penalidad total por pasajero niño
                </p>
              </div>
            </a-col>
            <a-col>
              <div class="mx-3 text-800">
                <p class="pb-0 mb-0">
                  <b
                    >$
                    {{
                      formatNumber({
                        number: filesStore.getPenalityADL * filesStore.getFile.adults,
                      })
                    }}</b
                  >
                </p>
                <p class="pb-0 mb-0" v-if="filesStore.getFile.children > 0">
                  <b
                    >$
                    {{
                      formatNumber({
                        number: filesStore.getPenalityCHD * filesStore.getFile.children,
                      })
                    }}</b
                  >
                </p>
              </div>
            </a-col>
          </a-row -->
        </div>
      </div>

      <div class="bg-white bordered p-4 my-4">
        <FileHeader v-bind:data="filesStore.getFile" v-bind:editable="false" />
      </div>

      <div
        class="bg-white bordered p-4 py-4"
        v-if="filesStore.getPenalityHotels.length > 0 || filesStore.getPenalityServices.length > 0"
      >
        <template v-if="filesStore.getPenalityHotels.length > 0">
          <div class="subtitle my-3 mx-2">
            <a-row type="flex" align="middle">
              <i class="bi bi-exclamation-triangle text-warning" style="font-size: 1.5rem"></i>
              <div class="d-flex mx-2 text-dark-warning">Hoteles que generan penalidad</div>
            </a-row>
          </div>

          <template v-for="(_hotel, h) in filesStore.getPenalityHotels" :key="'penalty-hotel-' + h">
            <hotel-merge
              :show_communication="false"
              type="cancellation"
              :flag_simulation="true"
              :flag_preview="true"
              :title="false"
              :from="_hotel"
              :buttons="false"
            />
          </template>
        </template>

        <template v-if="filesStore.getPenalityServices.length > 0">
          <hr class="line-dashed size-2 mt-5 mb-5" v-if="filesStore.getPenalityHotels.length > 0" />
          <div class="subtitle my-3 mx-2">
            <a-row type="flex" align="middle">
              <i class="bi bi-exclamation-triangle text-warning" style="font-size: 1.5rem"></i>
              <div class="d-flex mx-2 text-dark-warning">Servicios que generan penalidad</div>
            </a-row>
          </div>

          <template
            v-for="(_service, s) in filesStore.getPenalityServices"
            :key="'penalty-service-' + s"
          >
            <service-merge
              :show_communication="false"
              type="cancellation"
              :flag_simulation="true"
              :flag_preview="true"
              :title="false"
              :from="_service"
              :buttons="false"
            />
          </template>
        </template>
      </div>

      <div class="my-3">
        <a-row type="flex" justify="end" align="middle">
          <a-col>
            <a-button
              type="default"
              class="px-4 text-600"
              v-on:click="returnToProgram()"
              default
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              {{ t('global.button.cancel') }}
            </a-button>
            <a-button
              type="primary"
              default
              class="ms-2 px-4 text-600"
              v-on:click="nextStep()"
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              v-if="status_reason_id != ''"
              size="large"
            >
              {{ t('global.button.continue') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="step == 1">
      <div class="d-flex justify-content-between align-items-center mt-5 mb-5">
        <div class="title">
          <font-awesome-icon icon="fa-solid fa-comment-alt" class="text-danger" />
          Comunicación al proveedor
        </div>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram()"
            class="text-600"
            danger
            :loading="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.return_to_program') }}
          </a-button>
        </div>
      </div>

      <template v-if="filesStore.getFileItineraries.length > 0">
        <div class="subtitle my-5 mx-2 px-4" style="font-size: 18px">
          <a-row type="flex" align="middle" justify="start" style="gap: 10px">
            <a-col>
              <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="text-warning" />
            </a-col>
            <a-col>
              <div class="text-dark-warning">Comunicaciones a hoteles</div>
            </a-col>
          </a-row>
        </div>

        <template v-for="(itinerary, i) in filesStore.getFileItineraries">
          <template v-if="itinerary.entity == 'hotel'" :key="'penalty-hotel-' + h">
            <hotel-merge
              :show_communication="true"
              :title="false"
              type="cancellation"
              :flag_simulation="true"
              :flag_preview="false"
              :from="itinerary"
              :buttons="false"
            />
          </template>
        </template>

        <div class="subtitle my-5 mx-2 px-4" style="font-size: 18px">
          <a-row type="flex" align="middle" justify="start" style="gap: 10px">
            <a-col>
              <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="text-warning" />
            </a-col>
            <a-col>
              <div class="text-dark-warning">Comunicaciones a servicios</div>
            </a-col>
          </a-row>
        </div>

        <template v-for="(itinerary, i) in filesStore.getFileItineraries">
          <template v-if="itinerary.entity == 'service'" :key="'penalty-service-' + s">
            <service-merge
              :show_communication="true"
              :title="false"
              type="cancellation"
              :flag_simulation="true"
              :flag_preview="true"
              :from="itinerary"
              :buttons="false"
            />
          </template>
        </template>
      </template>
      <template v-else>
        <a-alert type="warning">
          <template #message>
            <div class="text-warning">
              No hay comunicaciones disponibles para hoteles y/o servicios, se puede proceder con la
              cancelación.
            </div>
          </template>
        </a-alert>
      </template>

      <div class="my-3">
        <a-row type="flex" justify="end" align="middle">
          <a-col>
            <a-button
              v-if="
                filesStore.getPenalityHotels.length > 0 || filesStore.getPenalityServices.length > 0
              "
              type="default"
              class="px-4 text-600"
              v-on:click="prevStep()"
              default
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              {{ t('global.button.cancel') }}
            </a-button>
            <a-button
              type="primary"
              default
              class="ms-2 px-4 text-600"
              v-on:click="processReservation()"
              :loading="filesStore.isLoading || filesStore.isLoadingAsync"
              size="large"
            >
              {{ t('global.button.continue') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="step == 2">
      <div class="mt-5 pt-5">
        <div class="text-center">
          <h2 class="text-danger text-800">
            Anulación de file {{ filesStore.getFile.fileNumber }} exitosa
          </h2>
          <div class="my-5">
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <svg
                  style="color: #1ed790"
                  class="feather feather-check-circle"
                  xmlns="http://www.w3.org/2000/svg"
                  width="5rem"
                  height="5rem"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <path d="M22 4 12 14.01l-3-3" />
                </svg>
              </a-col>
            </a-row>
          </div>
        </div>

        <div class="box-completed bg-light py-3 px-5">
          <a-row type="flex" align="middle" justify="space-between" class="mx-5">
            <a-col class="me-4">
              <span class="text-danger">
                <b>Detalle del file anulado</b>
              </span>
            </a-col>
            <a-col flex="auto">
              <span class="bg-white px-3 py-2 bordered w-100 ant-row-middle">
                <span class="d-flex mx-1">
                  <i class="bi bi-pencil"></i>
                </span>
                <span class="text-danger text-600">Motivo de anulación:</span>
                <span class="mx-2">{{ status_reason_name }}</span>
              </span>
            </a-col>
          </a-row>
          <a-row type="flex" align="top" justify="space-between" class="my-3 mx-5">
            <a-col>
              <p class="d-flex" style="gap: 5px">
                <b>Nombre del Cliente:</b>
                <span>{{ filesStore.getFile.clientName }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Nombre del File:</b>
                <span>{{ filesStore.getFile.description }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Nacionalidad:</b> <span>{{ showLanguage() }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Fecha de Reserva:</b>
                <span>{{ filesStore.getFile.dateIn }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>E-mail del ejecutivo:</b>
                <span class="text-lowercase"
                  >{{ filesStore.getFile.executiveCode }}@limatours.com.pe</span
                >
              </p>
            </a-col>
            <a-col>
              <p>
                <b class="text-danger">Fechas:</b>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Inicio del file:</b>
                <span>{{ formatDate(filesStore.getFile.dateIn) }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Finalización del file:</b>
                <span>{{ formatDate(filesStore.getFile.dateOut) }}</span>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Cantidad de pax: </b>
                <font-awesome-icon icon="fa-solid fa-user" />
                <span>{{ filesStore.getFile.adults }}</span>
                <font-awesome-icon icon="fa-solid fa-child-reaching" />
                <span>{{ filesStore.getFile.children }}</span>
              </p>
            </a-col>
            <a-col>
              <p>
                <b class="text-danger">Precio total de anulación del file:</b>
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Precio por pax:</b>
                <span
                  >USD
                  {{
                    formatNumber({
                      number:
                        (filesStore.getAllPenality / filesStore.getFile.paxs) *
                        filesStore.getFile.adults,
                    })
                  }}</span
                >
              </p>
              <p class="d-flex" style="gap: 5px" v-if="filesStore.getFile.children > 0">
                <b>Precio por pax:</b>
                <span
                  >USD
                  {{
                    formatNumber({
                      number:
                        (filesStore.getAllPenality / filesStore.getFile.paxs) *
                        filesStore.getFile.children,
                    })
                  }}</span
                >
              </p>
              <p class="d-flex" style="gap: 5px">
                <b>Total anulación del file:</b>
                <span>USD {{ formatNumber({ number: filesStore.getAllPenality }) }}</span>
              </p>
            </a-col>
          </a-row>
        </div>

        <div class="box-buttons mt-5">
          <a-row type="flex" justify="center" align="middle">
            <a-col>
              <a-button
                type="primary"
                class="px-4 text-600"
                v-on:click="returnToProgram()"
                default
                :loading="filesStore.isLoading || filesStore.isLoadingAsync"
                size="large"
              >
                {{ t('global.button.close') }}
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref, watch } from 'vue';
  import { debounce } from 'lodash-es';
  import { useRouter, useRoute } from 'vue-router';
  import { notification } from 'ant-design-vue';
  import {
    useFilesStore,
    useStatusesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
  } from '@store/files';
  import { useLanguagesStore } from '@store/global';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { formatNumber, formatDate, textPad } from '@/utils/files.js';
  import FileHeader from '@/components/files/reusables/FileHeader.vue';
  import HotelMerge from '@/components/files/reusables/HotelMerge.vue';
  import ServiceMerge from '@/components/files/reusables/ServiceMerge.vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n({
    useScope: 'global',
  });

  const router = useRouter();
  const route = useRoute();
  const filesStore = useFilesStore();
  const languagesStore = useLanguagesStore();

  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();

  const step = ref(0);
  const formState = ref({
    user: '',
    password: '',
  });
  const status_reason_id = ref('');
  const status_reason_name = ref('');
  const status_reasons = ref([]);

  const showMessage = (_step) => {
    let message = 'Finalizado';

    if (step.value < _step) {
      message = 'En espera';
    }

    if (step.value == _step) {
      message = 'En proceso';
    }

    return message;
  };

  const returnToProgram = () => {
    router.push({ name: 'files-edit', params: route.params });
  };

  onBeforeMount(async () => {
    const { id } = route.params;

    if (typeof filesStore.getFile.id == 'undefined') {
      await statusesStore.fetchAll();
      await haveInvoicesStore.fetchAll();
      await revisionStagesStore.fetchAll();

      await filesStore.getById({ id });
    }

    if (filesStore.getAllPenality > 0) {
      flag_validate.value = true;
    }

    let flag_continue = true;

    filesStore.getFileItineraries.map((itinerary) => {
      if (!itinerary.confirmation_status && flag_continue) {
        notification.error({
          message: 'Error al anular',
          description: `El ${itinerary.name} del día ${itinerary.date_in} no se encuentra confirmado. Por lo que no podemos anular el File.`,
        });

        flag_continue = false;
      }
    });

    if (!flag_continue) {
      let route = 'files-edit';
      let params = {
        id: filesStore.getFile.id,
      };

      router.push({ name: route, params: params });
    }

    await filesStore.fetchStatusReasons();
    await filesStore.calculatePenality();
    await filesStore.fetchAsumedBy();

    await filesStore.getStatusReasons.forEach(async (reason) => {
      if (reason.status_iso == 'XL') {
        status_reasons.value.push(reason);
      }
    });
    filesStore.finished();

    console.log('FILE: ', filesStore.getFile);
  });

  watch(
    () => step.value,
    async (newValue) => {
      console.log('Actualizando.. ', newValue);
      if (newValue == 0) {
        filesStore.inited();
        filesStore.clearPenality();
        await filesStore.calculatePenality();
        filesStore.finished();
      }
    },
    { immediate: true }
  );

  const nextStep = () => {
    if (step.value == 0) {
      if (flag_validate.value && asumed_by.value == '') {
        notification['warning']({
          message: `Error de penalidad`,
          description: 'Complete los datos de quién asume la penalidad',
          duration: 5,
        });

        return false;
      }
    }

    step.value++;
  };

  const prevStep = () => {
    step.value--;
  };

  const showLanguage = () => {
    return languagesStore.find(filesStore.getFile.lang).label;
  };

  const processReservation = async () => {
    let params = {
      file_id: filesStore.getFile.id,
      notas: '',
      attachments: [],
      status_reason_id: status_reason_id.value,
    };

    const status_reason = status_reasons.value.filter(
      (status_reason) => status_reason.id === status_reason_id.value
    );
    status_reason_name.value = status_reason[0].name;

    await filesStore.cancel(params);

    if (filesStore.getError != '') {
      notification.error({
        message: 'Error',
        description:
          'El FILE no se pudo anular. Por favor, actualice la página e inténtelo de nuevo.',
      });
    } else {
      nextStep();
    }
  };

  const searchExecutives = debounce(async (value) => {
    if (value != '' || (value == '' && executivesStore.getExecutives.length == 0)) {
      await executivesStore.fetchAll(value);
    }
  }, 300);

  const searchFiles = debounce(async (value) => {
    if (value != '') {
      await filesStore.fetchAll({ filter: value });
    }
  }, 300);

  const flag_validate = ref(false);
  const executive_id = ref('');
  const file_id = ref('');
  const asumed_by = ref('');
  const motive = ref('');

  // Función para emitir cambios de estado
  /*
  const emitChange = () => {
    emit('onChangeAsumed', {
      executive_id: executive_id.value,
      file_id: file_id.value,
      flag_validate: flag_validate.value,
      asumed_by: asumed_by.value,
      motive: motive.value,
    });
  };
  */

  // Observa cambios en las referencias
  /*
  watch([flag_validate, asumed_by, executive_id, file_id, motive], () => {
    emitChange();
  });
  */
</script>
