<script lang="ts" setup>
  import { computed, reactive, defineProps, ref, onMounted } from 'vue';
  import type { UnwrapRef } from 'vue';
  import dayjs, { Dayjs } from 'dayjs';

  import { useSiderBarStore } from '../../store/sidebar';
  import { useQuote } from '@/quotes/composables/useQuote';
  import IconClear from '@/quotes/components/icons/IconClear.vue';
  import IconSearch from '@/quotes/components/icons/IconSearch.vue';

  import type { DestinationsCountry, DestinationsState } from '@/quotes/interfaces';
  import { useQuoteServices } from '@/quotes/composables/useQuoteServices';
  import type {
    ServicesType,
    ServiceExperience,
    ServicesSubType,
  } from '@/quotes/interfaces/services';
  import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';
  import { useFilesStore } from '@/stores/files';

  import { useI18n } from 'vue-i18n';
  import { isArray, isObject } from 'lodash';
  const { t } = useI18n();
  const { getLang } = useQuoteTranslations();

  // store
  // TODO: refactor to use composable instead
  const storeSidebar = useSiderBarStore();

  const myButton = ref(null);

  // Composable
  const { quote, deleteServiceSelected } = useQuote();

  const {
    servicesTypes,
    serviceExperiences,
    serviceDurations,
    servicesDestinations,
    getToursAvailable,
    getCountryStates,
  } = useQuoteServices();

  const countries = computed(() => servicesDestinations.value!.destinationsCountries);
  const destinationsStates = computed(() => getCountryStates(searchFormState.country.code));
  const servicesTypesFiltered = computed(() => servicesTypes.value.filter((t) => t.code !== 'NA'));
  const experienceTypes = computed(() => serviceExperiences.value);
  const durationsTypes = computed(() => serviceDurations.value);

  const filesStore = useFilesStore();

  const props = defineProps({
    isFile: { type: Boolean, default: false },
    items: { type: Object, default: null },
  });

  // Form
  const searchFormRef = ref();

  interface SearchForm {
    date_from: Dayjs | undefined;
    country: DestinationsCountry;
    state: DestinationsState | null;
    experience_type: ServiceExperience | null;
    service_type: ServicesType | null;
    service_sub_category: ServicesSubType | null;
    service_name: string;
    passengers: [];
  }

  const searchFormState: UnwrapRef<SearchForm> = reactive({
    date_from: dayjs(quote.value.date_in),
    country: {
      code: '89',
      label: 'Perú',
    },
    state: null,
    experience_type: null,
    service_type: null,
    service_sub_category: null,
    service_name: '',
    passengers: [],
  });

  const dateFormat = 'DD/MM/YYYY';

  const countryChange = (value: { option: DestinationsCountry }) => {
    searchFormState.country = value.option;
    searchFormState.state = null;
  };
  const stateChange = (value: { option: DestinationsState }) => {
    searchFormState.state = value.option;
  };
  const categoryChange = (value: { option: ServicesType }) => {
    searchFormState.service_type = value.option;
  };
  const experienceChange = (value: { option: ServiceExperience }) => {
    searchFormState.experience_type = value.option;
  };
  const durationChange = (value: { option: ServicesSubType }) => {
    searchFormState.service_sub_category = value.option;
  };

  const search = () => {
    storeSidebar.setStatus(false, '', '');

    deleteServiceSelected();

    const destiny = {
      code: searchFormState.country.code + ',' + (searchFormState.state?.code ?? ''),
      label: searchFormState.country.label + ',' + (searchFormState.state?.label ?? ''),
    };

    searchFormRef.value
      .validate()
      .then(async () => {
        if (props.isFile) {
          filesStore.initedAsync();
          let adults_ = filesStore.getQuantityAdults(searchFormState.passengers);
          let children_ = filesStore.getQuantityChildren(searchFormState.passengers);
          filesStore.putSearchPassengers(searchFormState.passengers);

          let type = searchFormState.service_type?.id;
          let experience = searchFormState.experience_type?.id;
          let categories =
            searchFormState.service_sub_category?.id ??
            durationsTypes.value.map((duration) => duration.id);
          console.log('Categories: ', categories);

          if (!(isObject(categories) || isArray(categories))) {
            categories = [categories];
          }

          await filesStore.fetchServices({
            quantity_persons: {
              adults: adults_,
              child: children_,
              age_childs: [
                {
                  age: 1,
                },
              ],
            },
            date: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            client_id: localStorage.getItem('client_id'),
            origin: destiny,
            filter: searchFormState.service_name,
            type: type ? [type] : 'all',
            experience: experience ? [experience] : 'all',
            classification: 'all',
            category: categories,
            limit: 10,
            page: 1,
          });
          filesStore.finished();
          console.log('Services: ', filesStore.getServices);
        } else {
          await getToursAvailable({
            adults: quote.value.people[0].adults | 1,
            allWords: 1, // true
            children: quote.value.people[0].child,
            date_from: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            origin: destiny,
            service_name: searchFormState.service_name,
            service_type: searchFormState.service_type?.id ?? '',
            experience_type: searchFormState.experience_type?.id ?? '',
            service_sub_category: searchFormState.service_sub_category?.id ?? '',
          });

          storeSidebar.setStatus(true, 'service', 'search');
        }
      })
      .catch((error: string) => {
        console.log('error', error);
      });
  };
  const clearForm = () => {
    searchFormRef.value.resetFields();
  };

  onMounted(() => {
    if (
      props.items &&
      props.items.service &&
      props.items.service.service_origin &&
      props.items.service.service_type
    ) {
      let country = countries.value.find(
        (country) => country.code == props.items.service.service_origin[0].country_id
      );
      let city = {
        code: props.items.service.service_origin[0].state.id,
        label: props.items.service.service_origin[0].state.translations[0].value,
        country_code: country.code,
      };
      searchFormState.date_from = dayjs(props.items.date_in_format);
      searchFormState.country = country;
      searchFormState.state = city;
      props.items.service.service_type.label =
        props.items.service.service_type.translations[0].value;
      searchFormState.service_type = props.items.service.service_type;

      myButton.value.click();
    }
  });
</script>

<template>
  <a-form id="tours" class="container" ref="searchFormRef" :model="searchFormState">
    <div class="row-box">
      <div class="input-box">
        <label for="date_from">{{ t('quote.label.day_start') }}: *</label>
        <a-form-item
          name="date_from"
          :rules="{
            required: true,
            message: t('quote.label.select_start_day'),
          }"
        >
          <a-date-picker
            v-model:value="searchFormState.date_from"
            id="start-date"
            :format="dateFormat"
          />
        </a-form-item>
      </div>
      <div class="input-box" v-if="!props.isFile">
        <label for="country">{{ t('quote.label.country') }}: *</label>
        <a-form-item
          name="country"
          :rules="{ required: true, message: t('quote.label.select_country') }"
        >
          <a-select
            v-model:value="searchFormState.country"
            :options="countries"
            :field-names="{ label: 'label', value: 'code' }"
            label-in-value
            @change="countryChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="state">{{ t('quote.label.city') }}: *</label>
        <a-form-item
          name="state"
          :rules="{
            required: searchFormState.service_name == '',
            message: t('quote.label.select_city'),
          }"
        >
          <a-select
            showSearch
            optionFilterProp="label"
            v-model:value="searchFormState.state"
            :options="destinationsStates"
            :field-names="{ label: 'label', value: 'code' }"
            label-in-value
            @change="stateChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="experience_type">{{ t('quote.label.type_experience') }}: </label>
        <!--        <a-select ref="select" @focus="focus">-->
        <!--          <a-select-option value="aventura">Aventura</a-select-option>-->
        <!--          <a-select-option value="gastronomia">Gastronomía</a-select-option>-->
        <!--          <a-select-option value="naturaleza">Naturaleza</a-select-option>-->
        <!--          <a-select-option value="historia-cultura">Historia y Cultura</a-select-option>-->
        <!--          <a-select-option value="familia">Familia</a-select-option>-->
        <!--        </a-select>-->
        <a-form-item name="experience_type">
          <a-select
            v-model:value="searchFormState.experience_type"
            :options="experienceTypes"
            :field-names="{ label: 'label', value: 'id' }"
            label-in-value
            :allow-clear="true"
            @change="experienceChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="service_type">{{ t('quote.label.type_services') }}: </label>
        <a-form-item name="service_type">
          <a-select
            v-model:value="searchFormState.service_type"
            :options="servicesTypesFiltered"
            :field-names="{ label: 'label', value: 'id' }"
            label-in-value
            :allow-clear="true"
            @change="categoryChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box" v-if="props.isFile">
        <label for="passengers" class="d-block mb-1"
          >{{ t('global.label.passengers') }} <b class="text-danger">*</b></label
        >
        <a-form-item
          :rules="{
            required: true,
            message: t('files.message.select_passengers'),
          }"
        >
          <a-select
            mode="tags"
            id="passengers"
            v-model:value="searchFormState.passengers"
            :fieldNames="{ label: 'label', value: 'id' }"
            style="width: 180px"
            max-tag-count="responsive"
            :options="filesStore.getFilePassengers"
          >
          </a-select>
        </a-form-item>
      </div>
    </div>
    <div class="row-box">
      <div class="input-box duration">
        <label for="duration">{{ t('quote.label.duration') }}: </label>
        <!--        <a-select ref="select" @focus="focus">-->
        <!--          <a-select-option value="dia-entero">Día entero</a-select-option>-->
        <!--          <a-select-option value="medio-dia">Medio día</a-select-option>-->
        <!--        </a-select>-->
        <a-form-item name="duration">
          <a-select
            v-model:value="searchFormState.service_sub_category"
            :options="durationsTypes"
            :field-names="{ label: 'label', value: 'id' }"
            label-in-value
            :allow-clear="true"
            @change="durationChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box search">
        <label for="service_name">{{ t('quote.label.filter_by_words') }}: </label>
        <a-form-item name="service_name" class="w-100">
          <a-input
            v-model:value="searchFormState.service_name"
            :placeholder="t('quote.label.write_here')"
          />
        </a-form-item>
      </div>
      <div class="actions_buttons">
        <div class="text" @click="clearForm">
          <icon-clear />
          <span>{{ t('quote.label.clean_filters') }}</span>
        </div>
        <div class="search_button_container" ref="myButton" @click="search">
          <div class="search-button">
            <div class="content">
              <div class="icon">
                <icon-search />
              </div>
              <div class="text">{{ t('quote.label.search') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>

<style lang="scss" scoped>
  .input-box {
    &.duration {
      flex-grow: 0 !important;
      /* flex-shrink: 0 !important; */
      flex-basis: 19.2% !important;
    }
  }

  @media only screen and (max-width: 1400px) {
    form#tours.container {
      .input-box.search {
        .ant-row.ant-form-item {
          width: 39.5% !important;
        }
      }
    }
  }
</style>
